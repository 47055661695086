import React from "react"
import Layout from "../components/Layout/Layout"
import Seo from "../components/Core/Seo"
import { graphql } from "gatsby"
import styled from "styled-components"
import colors from "../styles/colors"
import breakpoints from "../styles/breakpoints"
import {
  DESKTOP_HEADER_HEIGHT,
  MOBILE_HEADER_HEIGHT,
} from "../components/Layout/Layout"
import Text from "src/components/Blocks/Text"

const PageContainer = styled.div`
  padding-top: calc(6.6vmax + ${DESKTOP_HEADER_HEIGHT}px);
  padding-left: 5vw;
  padding-right: 5vw;
  padding-bottom: 6.6vmax;
  max-width: ${breakpoints.xl};
  margin: auto;

  @media (max-width: ${breakpoints.md}) {
    padding-top: calc(6.6vmax + ${MOBILE_HEADER_HEIGHT}px);
  }
`

const Content = styled.div`
  @media (min-width: ${breakpoints.md}) {
    width: 75%;
    margin: auto;
  }
`

const Copy = styled.div`
  font-family: "Arial";
  h1 {
    font-size: 26px;
    line-height: 1.5;
    margin-bottom: 21px;
    font-family: inherit;
  }

  h2 {
    font-size: 19px;
    line-height: 1.5;
    margin-bottom: 16px;
    color: ${colors.black};
    font-family: inherit;
    b {
      font-family: inherit;
    }
  }

  p {
    color: ${colors.midGrey};
    font-family: inherit;
    font-size: 14px;
    line-height: 1.5;
    margin-bottom: 1em;
    :last-of-type {
      margin-bottom: 0;
    }

    b {
      font-family: inherit;
    }
  }
`

const TermsPage = (props) => {
  const { location, data } = props
  const path = location.pathname

  const page = data.contentfulSimplePage

  let sharingImage = false
  if (page.socialMediaImage) {
    sharingImage = page.socialMediaImage.file.url
  }

  return (
    <Layout dark bg={colors.lightGrey}>
      <Seo
        title={page.metaTitle}
        description={page.metaDescription}
        fullTitle={true}
        path={path}
        image={sharingImage}
      />
      <PageContainer>
        <Content>
          {page.pageTitle && <h1>{page.pageTitle}</h1>}
          <Copy>
            <Text content={page.content} />
          </Copy>
        </Content>
      </PageContainer>
    </Layout>
  )
}

export default TermsPage

export const pageQuery = graphql`
  query TermsQuery {
    contentfulSimplePage(slug: { eq: "terms-and-conditions" }) {
      ...simplePageFragment
    }
  }
`
